import React from 'react'
import { Container } from './tr-components/Container'
import { MessageCard } from './tr-components/MessageCard'
import { imagesURL } from './../config/endpoints'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const Logo = styled.div`
  max-width: 300px;
  padding: 0;

  img {
    max-height: 60px;
  }
`

export class RootPage extends React.Component {
  render() {
    return (
      <Container>
        <Helmet>
          <title>TalentReef</title>
        </Helmet>
        <Logo id="logo-wrapper">
          <img
            id="logo-img"
            className="logo"
            src={`${imagesURL}/logo/color-1000x476.png`}
            alt={'TalentReef'}
          />
        </Logo>
        <MessageCard title={'TalentReef'} message={'Welcome to TalentReef'} />
      </Container>
    )
  }
}

export default RootPage
