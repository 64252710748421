import React from 'react'
import { Colors } from '../tr-components/Colors'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Close from '@material-ui/icons/Close'

const styles = () => ({
  success: {
    backgroundColor: Colors.bgLight,
    color: Colors.darkGray,
  },
  error: {
    backgroundColor: Colors.red,
  },
  icon: {
    fontSize: 20,
  },
  iconButton: {
    fill: Colors.white,
  },
})

const SnackbarContentWrapper = (props) => {
  const { classes, message, onClose, variant } = props

  return (
    <SnackbarContent
      action={[
        <IconButton
          className={classes.iconButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <Icon>
            <Close />
          </Icon>
        </IconButton>,
      ]}
      className={classes[variant]}
      message={message}
    />
  )
}

export default withStyles(styles)(SnackbarContentWrapper)
