export const blue = '#1782a8'
export const gray = '#dddddd'
export const darkBlue = '#1782a8'
export const darkGray = '#464646'
export const white = '#ffffff'
export const bgDark = '#DADADA'
export const bgLight = '#f0f0f0'
export const lightGray = '#767676'
export const superLightBlue = '#eaf2f5'
export const placeHolder = '#a7a7a7'
export const errorRed = '#f44336'
export const lightBlue = '#d2e5eb'
export const lighterBlue = '#e4eff3'
export const title = '#666666'
export const disabled = '#e0e0e0'

export const green = '#34b644'
export const red = '#eb5347'
export const border = '#e1e1e1'

export const Colors = {
  blue,
  gray,
  darkBlue,
  darkGray,
  disabled,
  white,
  bgDark,
  bgLight,
  lightGray,
  superLightBlue,
  placeHolder,
  errorRed,
  lightBlue,
  lighterBlue,
  green,
  red,
  border,
}
