import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PaginationWrap } from './styles'
import RowsPerPage from './RowsPerPage'
import Showing from './Showing'
import PageButtons from './PageButtons'

export class Pagination extends Component {
  static propTypes = {
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    options: PropTypes.shape({
      totalRows: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
      visiblePageButtonsCount: PropTypes.number.isRequired,
    }).isRequired,
  }

  getTotalPages() {
    const { totalRows, rowsPerPage } = this.props.options
    return Math.ceil(totalRows / rowsPerPage)
  }

  /**
   * Return a list of pages for navigation.
   * Try to place current page in the middle if possible.
   * If only one page is available return an empty collection
   * @returns {*}
   */
  getPages() {
    const { visiblePageButtonsCount, page } = this.props.options
    const totalPages = this.getTotalPages()

    let pages = [page]
    let i = 0
    let front = true
    let back = true
    while (pages.length < visiblePageButtonsCount && (front || back)) {
      if (front && i % 2 === 0) {
        // add to the front if we still have previous
        const x = pages[0] - 1
        if (x > 0) pages = [x, ...pages]
        else front = false
      } else {
        // add to the back if we still have more
        const x = pages[pages.length - 1] + 1
        if (x <= totalPages) pages = [...pages, x]
        else back = false
      }
      i++
    }

    return pages
  }

  render() {
    const {
      onChangePage,
      onChangeRowsPerPage,
      options: { rowsPerPage, page, rowsPerPageOptions },
      style,
      className,
    } = this.props

    return (
      <PaginationWrap style={style} className={className}>
        <RowsPerPage
          selected={rowsPerPage}
          onClick={onChangeRowsPerPage}
          options={rowsPerPageOptions}
        />
        <Showing {...this.props.options} />
        <PageButtons
          total={this.getTotalPages()}
          current={page}
          onClick={onChangePage}
          pages={this.getPages()}
        />
      </PaginationWrap>
    )
  }
}
