import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  padding: 3rem 2rem !important;
  text-align: center;
  .subheading {
    margin-top: 0.2rem;
  }
`

export class MessageCard extends Component {
  static propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
  }

  render() {
    const { message, title } = this.props
    return (
      <StyledCard>
        {title && <Typography variant={'display1'}>{title}</Typography>}
        {message && (
          <Typography className="subheading" variant={'subheading'}>
            {message}
          </Typography>
        )}
      </StyledCard>
    )
  }
}
