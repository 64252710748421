import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ShowingWrap } from './styles'

export default class Showing extends Component {
  static propTypes = {
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    totalRows: PropTypes.number.isRequired,
  }

  getFirstDisplayedIndex() {
    const { rowsPerPage, page } = this.props
    return page * rowsPerPage - (rowsPerPage - 1)
  }

  getLastDisplayedIndex() {
    const { rowsPerPage, page, totalRows } = this.props
    return Math.min(page * rowsPerPage, totalRows)
  }

  getRangeString() {
    const start = this.getFirstDisplayedIndex()
    const last = this.getLastDisplayedIndex()
    return start === last ? start : `${start}-${last}`
  }

  render() {
    const total = this.props.totalRows
    const range = this.getRangeString()
    return (
      <ShowingWrap className="pagination-showing">
        <b>{range}</b> <FormattedMessage id="tr.pagination.of" defaultMessage="of" /> <b>{total}</b>
      </ShowingWrap>
    )
  }
}
