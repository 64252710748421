import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog/Dialog'
import { Loading } from './Loading'

const MyLoading = styled(Loading)`
  position: absolute;
  z-index: 5000;
  width: unset;
  height: unset;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  color: black;
  background-color: white;
  border-radius: 20px;
`

export class LoadingOverlay extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }

  render() {
    const { open, message } = this.props

    if (!open) return null

    return (
      <>
        <Dialog
          open={true}
          fullScreen={true}
          style={{
            opacity: 0.3,
          }}
          PaperProps={{
            style: {
              backgroundColor: 'black',
            },
          }}
        >
          <div />
        </Dialog>
        <MyLoading message={message} />
      </>
    )
  }
}
