import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import moment from 'moment'
import getFromObject from 'lodash/get'
import startCase from 'lodash/startCase'
import { FormattedMessage, injectIntl } from 'react-intl'

import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { Colors } from '../tr-components/Colors'
import { fetchAsset, reserveAssetKey } from '../../redux/reducers/marketingAssetsCollection'
import { getAssetsFile } from '../../static/marketingAssetsServices'
import { fetchLocations } from '../../redux/reducers/locations'

import AmplifyService from '../../services/amplify'
import ExtendedApplyModal from '../modals/ExtendedApplyModal'
import ShareButtons from './ShareButtons'
import {
  originUrl,
  ATSApplyURL,
  applyUrl,
  cognitoIdentityPoolId,
  pinpointProjectId,
} from '../../config/endpoints'

const StyledTitle = styled.h1`
  padding: 0;
  font-family: ${(font) => font.fontFamily};
  font-size: ${(font) => (font.size ? `${font.size}px` : '1rem')};
  color: ${(font) => (font.color ? font.color : Colors.lightGray)};
  font-weight: normal;
  text-align: left;
  text-transform: uppercase
  margin: 0;
`

const Article = styled.article`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px 50px;
  max-width: 980px
  margin: 0 auto;

  & > div:first-child {
    width: 60%;
  }
  & > div:last-child {
    width: 40%;
    padding-left: 20px;
    box-sizing: border-box;
    float left
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
    & > div:first-child,
    & > div:last-child {
      width: 100%;
      padding: 0;
    }
    .not-mobile {
      display: none;
    }
  }
`

const FontStyled = styled.div`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
`

const MobileOnly = styled.div`
  @media screen and (min-width: 780px) {
    display: none;
  }
`

const SectionBackground = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 20px 0;
  background: ${(props) => props.color || 'white'};
`

const Header = styled.header`
  max-width: 980px;
  padding: 10px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const Button = styled.button`
  padding: 8px 14px;
  text-transform: uppercase;
  font-size: 16px;
  background: ${(props) => props.color || Colors.lightGray};
  display: inline-block;
  color: white;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  @media screen and (min-width: 780px) {
    padding: 10px 20px;
  }
`
const ButtonsContainer = styled.div`
  @media screen and (max-width: 780px) {
    display: none;
  }
`
const AdditionalInfo = styled.div`
  padding: 0;
  padding-left: 20px;
  text-align: right;
  @media screen and (max-width: 780px) {
    text-align: left;
  }
`
const AdditionalInfoTitle = styled.h4`
  @media screen and (min-width: 780px) {
    margin-top: 0;
  }
`
const JobDescription = styled.div`
  @media screen and (max-width: 780px) {
    iframe,
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: 780px) {
    & :first-child {
      margin-top: 0;
    }
  }
`

const SharingPopper = styled(Popper)`
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
`

const SharingContainer = styled(Paper)`
  padding: 5px 10px;
`

const SharingTitle = styled.div`
  margin-bottom: 5px;
`

const facebook_pixel_id = process.env.REACT_APP_FACEBOOK_PIXEL_ID
const facebook_pixel_tracking_enabled = process.env.REACT_APP_FACEBOOK_PIXEL_TRACKING_ENABLED

export class PositionInformation extends React.Component {
  state = {
    showApplyModal: false,
    applyLink: null,
    anchorEl: null,
    isSharingPopperOpened: false,
    shortenedUrl: {
      email: '',
      sms: '',
    },
    isApplyModalExtended: false,
  }

  componentDidMount() {
    const {
      posting: { brand, brandId, clientId, clientName, department, jobId, location, positionId },
    } = this.props

    const referrer = document.referrer
    const utmSource =
      AmplifyService.extractUtmSource(window.location.search) ||
      AmplifyService.extractSourceFromReferrer(referrer)

    if (utmSource === 'facebook' && facebook_pixel_tracking_enabled === 'true') {
      // eslint-disable-next-line
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      window.fbq('init', facebook_pixel_id)
      window.fbq('track', 'PageView')
    }

    // This try/catch is to don't stop the loading of Job Description Page
    // when it was launched in preview mode and does not have the whole posting information.
    try {
      AmplifyService.initialize(cognitoIdentityPoolId, pinpointProjectId)

      AmplifyService.sendViewedEvent({
        brandId,
        brandName: brand,
        clientId,
        clientName,
        departmentId: department.id,
        departmentName: department.name,
        locationId: location.id,
        locationName: location.name,
        locationNumber: location.number,
        positionId,
        postingId: jobId,
        referrer,
        utmSource,
      })
    } catch (e) {
      console.warn(e)
    }
  }

  getAsset = (assetPath, details = true) => {
    const { assetsCollection, fetchAsset, clientId, reserveAssetKey, styles } = this.props

    let assetId = ''
    const asset = getFromObject(styles, assetPath, null)
    if (asset && asset.id) {
      return details ? asset.detail[asset.assetType] : asset
    } else {
      assetId = getFromObject(styles, assetPath + 'MaId', null)
    }

    if (assetId && assetsCollection && assetsCollection[assetId]) {
      return assetsCollection[assetId]
    } else if (assetId) {
      reserveAssetKey(assetId)
      fetchAsset(clientId, assetId)
      return {}
    }
    return {}
  }

  showApplyModal = () => {
    const {
      posting: { url },
      analyticsParameters,
    } = this.props
    const { isApplyModalExtended } = this.state
    if (!isApplyModalExtended) {
      const ATSUrl = `${ATSApplyURL + url}${analyticsParameters}`
      const applicationUrl = this.getModernApplyUrl(ATSUrl)
      window.open(applicationUrl)
    } else {
      this.setState({
        showApplyModal: true,
        applyLink: ATSApplyURL + url,
      })
    }
  }

  closeApplyModal = () => {
    this.setState({
      showApplyModal: false,
    })
  }

  convertStatus(status) {
    const mappedStatuses = { temporarySeasonal: 'Temporary / Seasonal' }
    const convertedStatus = mappedStatuses[status] || startCase(status)

    return convertedStatus
  }

  handleSharingPopper = (event) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
      isSharingPopperOpened: !this.state.isSharingPopperOpened,
      shortenedUrl: {
        email: this.getApplyUrl('email_share', 'email'),
        sms: this.getApplyUrl('sms_share', 'sms'),
      },
    })
  }

  handleClickAway = () => {
    this.setState({
      anchorEl: null,
      isSharingPopperOpened: false,
    })
  }

  getApplyUrl = (utm_source, utm_medium) => {
    const { jobId, clientId } = this.props.posting
    const { locale } = this.props
    return encodeURIComponent(
      `${originUrl}/clients/${clientId}/posting/${jobId}/${locale}?utm_source=${utm_source}&utm_medium=${utm_medium}`
    )
  }

  getModernApplyUrl = (redirectUrl) => {
    const { jobId } = this.props.posting
    return `${applyUrl}/apply/${jobId}?redirect=${encodeURIComponent(redirectUrl)}`
  }

  postingStatusToGoogleEmployment = (status) => {
    switch (status) {
      case 'fullTime':
        return 'FULL_TIME'
      case 'partTime':
        return 'PART_TIME'
      case 'seasonal':
        return 'temporary'
      case 'contract':
        return 'CONTRACT'
      case 'internship':
        return 'INTERNSHIP'
      case 'volunteer':
        return 'VOLUNTEER'
      default:
        return ''
    }
  }

  postingShiftsToWorkHours = (shifts) => {
    const { intl } = this.props

    const shiftsArray = []
    if (shifts.firstShift) {
      shiftsArray.push(intl.formatMessage({ id: 'positionInfo.first' }))
    }
    if (shifts.secondShift) {
      shiftsArray.push(intl.formatMessage({ id: 'positionInfo.second' }))
    }
    if (shifts.thirdShift) {
      shiftsArray.push(intl.formatMessage({ id: 'positionInfo.third' }))
    }

    return shiftsArray.join(', ')
  }

  buildGoogleStructuredData = (posting) => {
    const { intl } = this.props

    const structuredData = {
      '@context': 'http://schema.org/',
      '@type': 'JobPosting',
      title: posting.positionType,
      description: posting.description,
      identifier: {
        '@type': 'PropertyValue',
        name: posting.clientName,
        value: posting.jobId,
      },

      datePosted: posting.createdDate,
      validThrough: posting.endDate,

      image: getAssetsFile(posting.clientId, this.getAsset('header.logo').fileId),

      hiringOrganization: {
        '@type': 'Organization',
        name: posting.brand,
      },

      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: posting.address.street1,
          addressLocality: posting.address.city,
          addressRegion: posting.address.stateOrProvince,
          postalCode: posting.address.postalCode,
          addressCountry: posting.address.country,
        },
      },

      workHours: posting.isSalaried
        ? intl.formatMessage({ id: 'positionInfo.salaried' })
        : this.postingShiftsToWorkHours(posting.shifts),
    }

    const employmentType = this.postingStatusToGoogleEmployment(posting.contractType)
    if (employmentType) {
      structuredData.employmentType = employmentType
    }

    /**
     * Omitted data from Google Structured jobs:
     * Image urls may need to be ported somewhere usable
     * Salary information is not present in ES
     * Valid license and reliable transportation not in ES
     * Education requirement not in ES
     */

    return structuredData
  }

  render() {
    const { applyLink, showApplyModal, shortenedUrl, isApplyModalExtended } = this.state
    const {
      analyticsParameters,
      id,
      posting,
      availability,
      clientId,
      templateType,
      templateId,
      locale,
      styles: {
        fontTitle,
        position: {
          buttonShare,
          buttonApply,
          fontDescription,
          fontAdditionalInfoTitle,
          additionalInfoTitle,
          fontAdditionalInfo,
          additionalInfoFields,
        },
      },
      intl,
    } = this.props

    const Buttons = (
      <div id="position-info-buttons">
        <Button
          id="position-info-button-apply"
          onClick={this.showApplyModal}
          color={this.getAsset('position.buttonApply.backgroundColor').hex}
        >
          <FontStyled
            id="position-info-button-apply-font"
            fontFamily={this.getAsset('position.buttonApply.font.fontFamily', false).name}
            size={buttonApply && buttonApply.font && buttonApply.font.fontSize}
            color={this.getAsset('position.buttonApply.font.fontColor').hex}
          >
            <FormattedMessage id="careerSearch.applyButton" defaultMessage="Apply" />
          </FontStyled>
        </Button>
        <Button
          id="position-info-button-share"
          style={{ marginLeft: '20px' }}
          color={this.getAsset('position.buttonShare.backgroundColor').hex}
          target="_blank"
          onClick={this.handleSharingPopper}
        >
          <FontStyled
            id="position-info-button-share-font"
            fontFamily={this.getAsset('position.buttonShare.font.fontFamily', false).name}
            size={buttonShare && buttonShare.font && buttonShare.font.fontSize}
            color={this.getAsset('position.buttonShare.font.fontColor').hex}
          >
            <FormattedMessage id="general.share" defaultMessage="Share" />
          </FontStyled>
        </Button>
        <SharingPopper
          open={this.state.isSharingPopperOpened}
          anchorEl={this.state.anchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this.handleClickAway}>
              <Fade {...TransitionProps} timeout={100}>
                <SharingContainer>
                  <SharingTitle>
                    <FontStyled
                      fontFamily={this.getAsset('position.buttonShare.font.fontFamily', false).name}
                      size={buttonShare && buttonShare.font && buttonShare.font.fontSize}
                      color={this.getAsset('position.buttonShare.font.fontColorMaId').hex}
                    >
                      <FormattedMessage id="share.job" defaultMessage="Share job" />
                    </FontStyled>
                  </SharingTitle>
                  <ShareButtons
                    clientName={posting.clientName}
                    clientId={posting.clientId}
                    jobId={posting.jobId}
                    locale={locale}
                    positionType={posting.positionType}
                    positionLocation={`${posting.address.street1}, ${posting.address.city}, ${posting.address.country}, ${posting.address.stateOrProvince}, ${posting.address.postalCode}`}
                    templateId={templateId}
                    templateType={templateType}
                    shortenedUrl={shortenedUrl}
                  />
                </SharingContainer>
              </Fade>
            </ClickAwayListener>
          )}
        </SharingPopper>
      </div>
    )

    return (
      <SectionBackground id={id} color={this.getAsset('backgroundColor').hex}>
        <Helmet>
          {posting && (
            <script type="application/ld+json">
              {JSON.stringify(this.buildGoogleStructuredData(posting))}
            </script>
          )}
        </Helmet>
        <Header id="position-info-header">
          <div>
            <StyledTitle
              id="position-info-title"
              fontFamily={this.getAsset('fontTitle.fontFamily', false).name}
              size={fontTitle && fontTitle.fontSize}
              color={this.getAsset('fontTitle.fontColor').hex}
            >
              <span>{posting.positionType}</span> <br />
              <span style={{ fontSize: fontTitle ? fontTitle.fontSize - 4 : '' }}>
                {posting.address.city}, {posting.address.stateOrProvince}
              </span>
            </StyledTitle>
          </div>
          <ButtonsContainer>{Buttons}</ButtonsContainer>
        </Header>
        <Article>
          <div style={{ paddingBottom: '10px' }}>
            <div id="position-info-mobile-buttons-container">
              <MobileOnly>{Buttons}</MobileOnly>
            </div>
            <FontStyled
              id="position-info-posting-description"
              fontFamily={this.getAsset('position.fontDescription.fontFamily', false).name}
              size={fontDescription && fontDescription.fontSize}
              color={this.getAsset('position.fontDescription.fontColor').hex}
            >
              <JobDescription dangerouslySetInnerHTML={{ __html: posting.description }} />
            </FontStyled>
            <br />
            <FontStyled
              id="position-info-already-applied"
              fontFamily={this.getAsset('position.fontDescription.fontFamily', false).name}
              size={fontDescription && fontDescription.fontSize}
              color={this.getAsset('position.fontDescription.fontColor').hex}
            >
              <a href={applyUrl + '/applicant/recover-applications'}>
                {intl.formatMessage({ id: 'apply.alreadyApplied' })}
              </a>
            </FontStyled>
          </div>
          <MobileOnly>{Buttons}</MobileOnly>
          {additionalInfoFields && (
            <AdditionalInfo id="position-info-additional-info">
              <AdditionalInfoTitle id="position-info-additional-info-title">
                <FontStyled
                  id="position-info-additional-info-title-font"
                  fontFamily={
                    this.getAsset('position.fontAdditionalInfoTitle.fontFamily', false).name
                  }
                  size={fontAdditionalInfoTitle && fontAdditionalInfoTitle.fontSize}
                  color={this.getAsset('position.fontAdditionalInfoTitle.fontColor').hex}
                >
                  {additionalInfoTitle || intl.formatMessage({ id: 'positionInfo.positionInfo' })}
                </FontStyled>
              </AdditionalInfoTitle>
              <div id="position-info-additional-info-fields">
                <FontStyled
                  id="position-info-additional-info-fields-font"
                  fontFamily={this.getAsset('position.fontAdditionalInfo.fontFamily', false).name}
                  size={fontAdditionalInfo && fontAdditionalInfo.fontSize}
                  color={this.getAsset('position.fontAdditionalInfo.fontColor').hex}
                >
                  {additionalInfoFields.includes('Company') && (
                    <p className="not-mobile">
                      <FormattedMessage id="positionInfo.company" defaultMessage="Company" />:{' '}
                      {posting.clientName}
                    </p>
                  )}
                  {additionalInfoFields.includes('Position') && (
                    <p className="not-mobile">
                      <FormattedMessage id="positionInfo.position" defaultMessage="Position" />:{' '}
                      {posting.positionType}
                    </p>
                  )}
                  {additionalInfoFields.includes('Status') && (
                    <p>
                      <FormattedMessage id="positionInfo.status" defaultMessage="Status" />:{' '}
                      {this.convertStatus(posting.contractType)}
                    </p>
                  )}
                  {additionalInfoFields.includes('Shift') && (
                    <p>
                      <FormattedMessage id="positionInfo.shift" defaultMessage="Shift" />:{' '}
                      {posting.shifts && (
                        <span>{this.postingShiftsToWorkHours(posting.shifts)}</span>
                      )}
                    </p>
                  )}
                  {additionalInfoFields.includes('Req #') && (
                    <p className="not-mobile">
                      <FormattedMessage id="positionInfo.req" defaultMessage="Req #" />:{' '}
                      {posting.jobId}
                    </p>
                  )}
                  {additionalInfoFields.includes('Date Posted') && (
                    <p className="not-mobile">
                      <FormattedMessage id="positionInfo.date" defaultMessage="Date Posted" />:{' '}
                      {moment(posting.createdDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}
                    </p>
                  )}
                  {additionalInfoFields.includes('Location') && posting.address && (
                    <p>
                      <FormattedMessage id="positionInfo.location" defaultMessage="Location" />:{' '}
                      {`${posting.address.street1}, ${posting.address.city},
                    ${posting.address.country},
                    ${posting.address.stateOrProvince},
                    ${posting.address.postalCode}
                  `}
                    </p>
                  )}
                  {additionalInfoFields.includes('Job Category') && (
                    <p className="not-mobile">
                      <FormattedMessage id="positionInfo.category" defaultMessage="Job Category" />:{' '}
                      {posting.category}
                    </p>
                  )}
                </FontStyled>
              </div>
            </AdditionalInfo>
          )}
        </Article>
        {showApplyModal && isApplyModalExtended && (
          <ExtendedApplyModal
            id="position-info-apply-modal"
            open={showApplyModal}
            clientId={clientId}
            templateId={templateId}
            templateType={templateType}
            analyticsParameters={analyticsParameters}
            locale={locale}
            jobInfo={posting}
            URL={applyLink}
            availability={availability}
            onCancel={this.closeApplyModal}
            color={this.getAsset('position.buttonApply.backgroundColor').hex}
            size={buttonApply && buttonApply.font && buttonApply.font.fontSize}
            fontFamily={this.getAsset('position.buttonApply.font.fontFamily', false).name}
            fontColor={this.getAsset('position.buttonApply.font.fontColorMaId').hex}
          />
        )}
      </SectionBackground>
    )
  }
}

const mapStateToProps = ({ assetsCollection, template, jobPosting: { posting }, user }) => ({
  assetsCollection: assetsCollection.assets,
  templateType: template.type,
  posting,
  analyticsParameters: user.analyticsParameters,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsset,
      fetchLocations,
      reserveAssetKey,
    },
    dispatch
  )

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PositionInformation))
