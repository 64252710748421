import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import MuiTextField from '@material-ui/core/TextField'
import BrightnessIcon from '@material-ui/icons/Brightness1'
import styled from 'styled-components'
import { red } from '../Colors'
import Tooltip from '@material-ui/core/Tooltip'
import { injectIntl } from 'react-intl'
import { t } from '../Intl'

const RequiredIcon = styled(BrightnessIcon)`
  margin-left: 0.5rem;
  vertical-align: middle;
  && {
    fill: ${red};
    height: 0.35rem;
    width: 0.35rem;
  }
`

export class TextFieldInternal extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    helper: PropTypes.string,
    label: PropTypes.any,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    required: PropTypes.bool,
  }

  static defaultProps = {
    required: false,
  }

  render() {
    const { name, helper, label, fullWidth, className, required, intl, ...rest } = this.props
    return (
      <Field name={name}>
        {({ input, meta }) => {
          const showError = !!(meta.error && (meta.touched || meta.dirty))

          return (
            <FormControl
              fullWidth={fullWidth}
              error={showError}
              margin={'normal'}
              className={className}
            >
              {label && (
                <Typography
                  component="span"
                  variant={'subheading'}
                  style={{ color: rest.disabled ? '#afaeae' : 'inherit', display: 'inline' }}
                >
                  {label}
                  {required && (
                    <Tooltip
                      title={intl.formatMessage(t('tr.requiredField', 'This is a required field'))}
                      enterDelay={500}
                    >
                      <RequiredIcon />
                    </Tooltip>
                  )}
                </Typography>
              )}
              <MuiTextField
                {...input}
                {...rest}
                helperText={showError ? meta.error : helper}
                error={!!(meta.error && (meta.touched || meta.dirty))}
              />
            </FormControl>
          )
        }}
      </Field>
    )
  }
}

export const TextField = injectIntl(TextFieldInternal)
