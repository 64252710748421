import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Numbers, PageButton } from './styles'

export default class PageButtons extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    current: PropTypes.number.isRequired,
    pages: PropTypes.arrayOf(PropTypes.number).isRequired,
    total: PropTypes.number.isRequired,
  }

  handleClick = (page) => () => this.props.onClick(page)

  render() {
    const { current, pages, total } = this.props
    const previous = current > 1
    const next = current < total

    return (
      <div style={{ display: 'flex' }} className="page-buttons-wrap">
        {previous && (
          <PageButton
            className="page-button-first"
            aria-label="previous page"
            data-test="pagination_previous"
            onClick={this.handleClick(1)}
          >
            {'<<'}
          </PageButton>
        )}
        {previous && (
          <PageButton
            className="page-button-previous"
            aria-label="first page"
            data-test="pagination_first"
            onClick={this.handleClick(current - 1)}
          >
            {'<'}
          </PageButton>
        )}

        <Numbers className="page-button-numbers">
          {pages.length > 1 &&
            pages.map((i) => {
              return (
                <PageButton
                  className={`page-button-number ${i === current ? 'page-button-selected' : ''}`}
                  aria-label={`page ${i}`}
                  data-test={`page_${i}`}
                  key={i}
                  onClick={this.handleClick(i)}
                  selected={i === current}
                >
                  {i}
                </PageButton>
              )
            })}
        </Numbers>

        {next && (
          <PageButton
            className="page-button-next"
            aria-label="next page"
            data-test="pagination_next"
            onClick={this.handleClick(current + 1)}
          >
            {'>'}
          </PageButton>
        )}
        {next && (
          <PageButton
            className="page-button-last"
            aria-label="last page"
            data-test="pagination_last"
            onClick={this.handleClick(total)}
          >
            {'>>'}
          </PageButton>
        )}
      </div>
    )
  }
}
