import styled from 'styled-components'
import { darkBlue, darkGray, white } from '../Colors'

const RowsPerPageWrap = styled.div`
  color: ${darkGray};
  flex-grow: 1;

  span {
    margin-right: 0.75rem;
  }
`

const PageButton = styled.button`
  background: ${({ selected }) => (selected ? darkBlue : 'transparent')};
  border: none;
  border-radius: 50%;
  color: ${({ selected }) => (selected ? white : darkBlue)};
  cursor: pointer;
  font-size: 0.75rem;
  height: 26px;
  line-height: 1.5em;
  padding: 0;
  width: 26px;
  user-select: none;
`

const PaginationWrap = styled.div.attrs({
  className: 'pagination-wrap',
})`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
  padding: 0.75rem 0;
  align-items: center;

  @media (max-width: 999px) {
    .rows-per-page-wrap {
      display: none;
    }
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`
const ShowingWrap = styled.div`
  color: ${darkGray};
  margin-right: 0.75rem;
`

const Numbers = styled.div`
  > button {
    margin-right: 5px;
  }
`

export { Numbers, PageButton, PaginationWrap, RowsPerPageWrap, ShowingWrap }
