import React from 'react'
import { connect } from 'react-redux'

import Slider from 'react-slick'
import getFromObject from 'lodash/get'
import styled from 'styled-components'

import { Colors } from '../tr-components/Colors'

import Icon from '@material-ui/core/Icon'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

import { bindActionCreators } from 'redux'
import { fetchAsset, reserveAssetKey } from '../../redux/reducers/marketingAssetsCollection'
import { getAssetsFile } from '../../static/marketingAssetsServices'

const SectionTitle = styled.h1`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
  padding: 0 75px;
  margin: 0 0 15px;
  text-align: center;
`

const SectionBackground = styled.div`
  width: 100%;
  padding: 25px 0;
  background: ${(props) => props.color || 'white'};
`

const SectionContentWrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
`

const JobCategoriesSlider = styled(Slider)`
  width: 90%;
  margin: auto;

  @media screen and (max-width: 1024px) {
    width: 85%;
  }

  @media screen and (max-width: 780px) {
    width: 75%;
  }

  & .next-slide-button {
    position: absolute;
    right: -39px;
    top: 40%;
    cursor: pointer;
    font-size: 50px;
    color: ${Colors.white};
  }
  & .prev-slide-button {
    position: absolute;
    left: -39px;
    top: 40%;
    cursor: pointer;
    font-size: 50px;
    color: ${Colors.white};
  }
`

const SlideContainer = styled.div`
  padding: 20px;
`

const SlideWraper = styled.div`
  border: 2px solid ${Colors.white};
  position: relative;
`

const SlideImageContainer = styled.div`
  height: 270px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const SlideTitleContainer = styled.div`
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const SlideTitle = styled.h3`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0 20px;
  overflow: hidden;
  word-break: break-word;
`

const SlideLink = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const SliderNextArrow = (props) => {
  const { onClick } = props
  return (
    <Icon className="next-slide-button" aria-label="next_slide" onClick={onClick}>
      <ChevronRight fontSize="inherit" color="inherit" />
    </Icon>
  )
}

const SliderPrevArrow = (props) => {
  const { onClick } = props
  return (
    <Icon className="prev-slide-button" aria-label="back_slide" onClick={onClick}>
      <ChevronLeft fontSize="inherit" color="inherit" />
    </Icon>
  )
}

class JobCategories extends React.Component {
  getAsset = (assetPath, details = true) => {
    const { assetsCollection, fetchAsset, clientId, reserveAssetKey, styles } = this.props

    let assetId = ''
    const asset = getFromObject(styles, assetPath, null)
    if (asset && asset.id) {
      return details ? asset.detail[asset.assetType] : asset
    } else {
      assetId = getFromObject(styles, assetPath + 'MaId', null)
    }

    if (assetId && assetsCollection && assetsCollection[assetId]) {
      return assetsCollection[assetId]
    } else if (assetId) {
      reserveAssetKey(assetId)
      fetchAsset(clientId, assetId)
      return {}
    }
    return {}
  }

  getJobCategoriesList = (jobCategories) => {
    const { clientId } = this.props
    const viewJobCategories = jobCategories
      .map((job, index) => ({ ...job, index }))
      .filter((jobCategorie) => {
        return (
          jobCategorie.link.url ||
          jobCategorie.text ||
          jobCategorie.imageMaId ||
          jobCategorie.boxColorMaId
        )
      })
    return viewJobCategories.map((jobCategory) => {
      const slideTitleStyles = jobCategory.fontText
      const slideTitleStylesPath = `jobCategoryList.jobCategories[${jobCategory.index}].fontText`
      return (
        <SlideContainer key={jobCategory.index}>
          <SlideWraper
            id="job-categories-slider-wrapper"
            style={{
              backgroundColor: this.getAsset(
                `jobCategoryList.jobCategories[${jobCategory.index}].boxColor`
              ).hex,
              borderColor: this.getAsset(
                `jobCategoryList.jobCategories[${jobCategory.index}].boxColor`
              ).hex,
            }}
          >
            <SlideImageContainer
              id="job-categories-slider-image-container"
              style={{
                backgroundImage: `url(${getAssetsFile(
                  clientId,
                  this.getAsset(`jobCategoryList.jobCategories[${jobCategory.index}].image`).fileId
                )})`,
              }}
            />
            {jobCategory.text && (
              <SlideTitleContainer id="job-categories-slider-title">
                <SlideTitle
                  id="job-categories-slider-title-font"
                  fontFamily={this.getAsset(`${slideTitleStylesPath}.fontFamily`, false).name}
                  size={slideTitleStyles && slideTitleStyles.fontSize}
                  color={this.getAsset(`${slideTitleStylesPath}.fontColor`).hex}
                >
                  {jobCategory.text}
                </SlideTitle>
              </SlideTitleContainer>
            )}
            <SlideLink
              href={jobCategory.link.url}
              target="_blank"
              id="job-categories-slider-link"
            />
          </SlideWraper>
        </SlideContainer>
      )
    })
  }

  render() {
    const {
      id,
      styles: { title, fontTitle },
    } = this.props
    const jobCategories = this.props.styles.jobCategoryList.jobCategories

    const sliderSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 667,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <SectionBackground id={id} color={this.getAsset('backgroundColor').hex}>
        <SectionContentWrapper id="job-categories-wrapper">
          <SectionTitle
            id="job-categories-title-font"
            fontFamily={this.getAsset('fontTitle.fontFamily', false).name}
            size={fontTitle && fontTitle.fontSize}
            color={this.getAsset('fontTitle.fontColor').hex}
          >
            {title}
          </SectionTitle>
          <JobCategoriesSlider {...sliderSettings} id="job-categories-slider">
            {this.getJobCategoriesList(jobCategories)}
          </JobCategoriesSlider>
        </SectionContentWrapper>
      </SectionBackground>
    )
  }
}

const mapStateToProps = ({ assetsCollection }) => ({
  assetsCollection: assetsCollection.assets,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsset,
      reserveAssetKey,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(JobCategories)
