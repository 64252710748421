import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

const SpacedDialogActions = styled(DialogActions)`
  && {
    margin: 8px 24px 24px 24px;
  }
`

export class NotificationDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    justifyActions: PropTypes.string,
  }

  static defaultProps = {
    title: <FormattedMessage id="tr.notification" defaultMessage="Notification" />,
    buttonLabel: <FormattedMessage id="tr.close" defaultMessage="Close" />,
    justifyActions: 'flex-end',
  }

  render() {
    const { onClose, title, buttonLabel, justifyActions, children, ...rest } = this.props

    return (
      <Dialog className="tr-notification-dialog" onClose={onClose} {...rest}>
        <DialogTitle className="tr-notification-dialog-title">{title}</DialogTitle>
        <DialogContent className="tr-notification-dialog-content">{children}</DialogContent>
        <SpacedDialogActions
          className="tr-notification-actions"
          style={{ justifyContent: justifyActions }}
        >
          <Button onClick={onClose}>{buttonLabel}</Button>
        </SpacedDialogActions>
      </Dialog>
    )
  }
}
