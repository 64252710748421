import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  span {
    margin-top: 0.5rem;
  }
`

export class Loading extends Component {
  static propTypes = {
    message: PropTypes.string,
    padding: PropTypes.string,
  }

  static defaultProps = {
    padding: '1rem',
  }

  render() {
    const { message, padding, className } = this.props
    return (
      <Wrap padding={padding} className={className}>
        <CircularProgress />
        {message && <span>{message}</span>}
        {!message && <FormattedMessage id="tr.loading" defaultMessage="Loading..." />}
      </Wrap>
    )
  }
}
