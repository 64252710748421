import PropTypes from 'prop-types'
import styled from 'styled-components'
import { blue, lightGray } from '../Colors'

export const Table = styled.div.attrs({
  className: 'table',
})`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  color: ${lightGray};
  border-bottom: ${({ showBottomBorder }) => (showBottomBorder ? '1px solid #ccc' : 'none')};

  a {
    color: ${blue};
    text-decoration: none;
  }
`
Table.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  showBottomBorder: PropTypes.bool,
}

export const TableHead = styled.div.attrs({
  className: 'thead',
})`
  background-color: ${({ bgcolor }) => bgcolor};
  ${({ sticky }) => sticky && stickyHeader()};
`
TableHead.propTypes = {
  bgcolor: PropTypes.string,
  sticky: PropTypes.bool,
}

export const TableBody = styled.div.attrs({
  className: 'tbody',
})`
  .trow:last-of-type {
    border-bottom-color: transparent;
  }
`

export const TableRow = styled.div.attrs({
  className: 'trow',
})`
  background-color: white;
  display: flex;
  padding: 0 1rem 0 1rem;
  border: 1px solid #cccccc;
  border-top: 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
`
TableRow.propTypes = {
  clickable: PropTypes.bool,
}

export const TableCell = styled.div.attrs({
  className: 'tcell',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.8rem;
  line-height: 2rem;
  width: ${(props) => props.width || 'auto'};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ css }) => css || ''}
`
TableCell.propTypes = {
  width: PropTypes.string,
  clickable: PropTypes.bool,
  css: PropTypes.string,
}

export const HeaderRow = styled(TableRow).attrs({
  className: 'hrow',
})`
  background-color: rgba(0, 0, 0, 0) !important;
  border-left: 0;
  border-right: 0;
  height: 2rem;
  && {
    .tcell {
      padding: 0 0.8rem 0 0.8rem;
    }
  }
`

export const TableFooter = styled(TableRow).attrs({
  className: 'tfooter',
})`
  background-color: ${({ bgcolor }) => bgcolor};
  border-left: 0;
  border-right: 0;
  border-bottom-color: transparent;
  padding-left: 0;
  padding-right: 0;
  border-top: 1px solid #ccc;
  ${({ sticky }) => sticky && stickyFooter()};
`
TableFooter.propTypes = {
  sticky: PropTypes.bool,
  bgcolor: PropTypes.string,
}

function stickyHeader() {
  return `
    position: sticky;
    top: 0;
    z-index: 3;
  `
}

function stickyFooter() {
  return `
    position: sticky;
    bottom: 0;
    z-index: 3;
  `
}
