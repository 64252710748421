import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { RowsPerPageWrap, PageButton } from './styles'

export default class RowsPerPage extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.number).isRequired,
  }

  handleClick = (rowsPerPage) => () => this.props.onClick(rowsPerPage)

  render() {
    const { selected, options } = this.props

    return (
      <RowsPerPageWrap className="rows-per-page-wrap">
        <FormattedMessage id="tr.pagination.itemsperpage" defaultMessage="Items Per Page" />
        {options.map((option) => (
          <PageButton
            className={`pagination-rows-per-page-button ${
              selected === option ? 'rpp-selected' : ''
            }`}
            data-test={`items_per_page_${option}`}
            aria-label={option}
            key={option}
            selected={selected === option}
            onClick={this.handleClick(option)}
          >
            {option}
          </PageButton>
        ))}
      </RowsPerPageWrap>
    )
  }
}
