import styled from 'styled-components'
import PropTypes from 'prop-types'
import { bgDark, white } from '../Colors'

export const Container = styled.div.attrs({
  className: 'tr-container',
  'data-test': (props) => props['data-test'],
})`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : white)};
  border: ${({ transparent }) => (transparent ? 'none' : `1px solid ${bgDark}`)};
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding, transparent }) => (padding ? padding : !transparent ? '2rem' : '3px')};
  height: ${({ height }) => height || 'auto'};
  /* shrink has to be 1 or container will never overflow */
  ${({ fillY }) => (fillY ? 'flex 1 1 auto;' : '')}
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 1rem;'}
`

Container.propTypes = {
  transparent: PropTypes.bool,
  margin: PropTypes.string,
  bottomMargin: PropTypes.bool,
  padding: PropTypes.string,
}

Container.defaultProps = {
  transparent: true,
  margin: '0.5rem',
  bottomMargin: false,
}
