import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

const SpacedDialogActions = styled(DialogActions)`
  && {
    margin: 8px 24px 24px 24px;
  }
`

export class ConfirmDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    cancelLabel: PropTypes.string,
    acceptLabel: PropTypes.string,
    title: PropTypes.string,
    justifyActions: PropTypes.string,
  }

  static defaultProps = {
    cancelLabel: 'Cancel',
    acceptLabel: 'OK',
    title: 'Confirm',
    open: false,
    justifyActions: 'space-between',
  }

  render() {
    const {
      title,
      children,
      cancelLabel,
      acceptLabel,
      onAccept,
      onCancel,
      justifyActions,
      ...rest
    } = this.props
    return (
      <Dialog onClose={onCancel} {...rest}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <SpacedDialogActions style={{ justifyContent: justifyActions }}>
          <Button color={'secondary'} onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button onClick={onAccept}>{acceptLabel}</Button>
        </SpacedDialogActions>
      </Dialog>
    )
  }
}
