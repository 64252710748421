import { Colors } from './Colors'
import { createMuiTheme } from '@material-ui/core/styles'

export const headerMenuHeight = 40
export const sideMenuWidth = 250
export const breakPoint = 999

const defaultTheme = createMuiTheme()
export const theme = createMuiTheme({
  tr: {
    sideMenu: {
      width: `${sideMenuWidth}px`,
    },
    headerMenu: {
      height: `${headerMenuHeight}px`,
    },
    breakPoint: `${breakPoint}px`,
  },
  props: {
    MuiRadio: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiPopover: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    },
  },
  palette: {
    primary: {
      main: Colors.blue,
      contrastText: '#ffffff',
    },
    secondary: {
      main: Colors.gray,
      contrastText: Colors.blue,
    },
    text: {
      primary: Colors.lightGray,
    },
    action: {
      hover: Colors.lightGray,
    },
    error: {
      main: '#e18e8f',
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'rgba(67,160,71, 0.8)',
      },
    },
    MuiCheckbox: {
      root: {
        color: Colors.blue,
        height: 'unset',
        width: 'unset',
        marginRight: '0.6rem',
      },
    },
    MuiRadio: {
      root: {
        width: 'unset',
        height: 'unset',
        marginRight: '0.3rem',
      },
    },
    MuiDivider: {
      root: {
        height: '2px;',
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'unset',
        '&:before': {
          height: '0',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0',
      },
      expandIcon: {
        top: 'unset',
      },
      content: {
        flexDirection: 'column',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '2px',
      },
    },
    MuiCard: {
      root: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '1rem',
        marginBottom: '1rem',
      },
    },
    MuiFormControlLabel: {
      root: {
        display: 'flex',
        margin: '1rem 16px 0 0',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: Colors.blue,
      },
      root: {
        boxShadow: `inset 0px -2px 0px 0px ${Colors.bgDark}`,
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        opacity: 1,
        zIndex: 3,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '2px solid #cfcfcf',
        },
        '&:after': {
          borderBottom: '2px solid rgba(20, 113, 146, 0.4)',
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        overflowX: 'hidden',
        borderRight: `1px solid ${Colors.bgDark}`,
      },
    },
    MuiMenuItem: {
      selected: {
        '&:hover': {
          backgroundColor: Colors.superLightBlue,
        },
      },
    },
    MuiListItem: {
      selected: {
        backgroundColor: Colors.superLightBlue + '!important',
        '&:hover': {
          backgroundColor: Colors.superLightBlue,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: Colors.superLightBlue,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        fill: Colors.darkBlue,
        width: '24px',
        height: '24px',
      },
    },
    MuiListItemText: {
      root: {
        padding: 0,
      },
      primary: {
        fontSize: '0.8rem',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: Colors.darkBlue,
      },
      root: {
        zIndex: defaultTheme.zIndex.drawer + 1,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: 'space-between',
      },
      regular: {
        minHeight: `${headerMenuHeight}px !important`,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      contained: {
        boxShadow: 0,
      },
    },
    MuiSvgIcon: {
      root: {},
    },
  },
  typography: {
    body1: {
      color: Colors.lightGray,
    },
    body2: {
      color: Colors.lightGray,
    },
    title: {
      color: Colors.lightGray,
      fontWeight: '600',
    },
    subheading: {
      color: Colors.lightGray,
    },
  },
})
