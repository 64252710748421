import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { Field } from 'react-final-form'

export class RadioButton extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  render() {
    const { name, label, value, className, ...rest } = this.props

    return (
      <Field name={name} type="radio" value={value}>
        {({ input }) => {
          return (
            <FormControlLabel
              label={label}
              className={className}
              control={<Radio {...rest} {...input} />}
            />
          )
        }}
      </Field>
    )
  }
}
