import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import MuiCheckbox from '@material-ui/core/Checkbox'
import styled from 'styled-components'

const StyledLabel = styled(FormControlLabel)`
  && {
    margin-left: unset;
    margin-right: unset;
    ${(p) => p.top && 'align-items: flex-start;'}
  }
`

export class Checkbox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    helper: PropTypes.string,
    label: PropTypes.string,
    inlineLabel: PropTypes.bool,
    className: PropTypes.string,
    top: PropTypes.bool,
  }

  static defaultProps = {
    inlineLabel: true,
    top: false,
  }

  render() {
    const { name, helper, label, value, fullWidth, inlineLabel, className, top, ...rest } =
      this.props

    return (
      <Field name={name} type="checkbox" value={value}>
        {({ input, meta }) => {
          const showError = !!(meta.error && (meta.touched || meta.dirty))
          const showHelper = !!(helper || showError)

          if (inlineLabel) {
            return (
              <div>
                <StyledLabel
                  label={label}
                  className={className}
                  top={top}
                  control={<MuiCheckbox {...input} value="" {...rest} />}
                />
                {showHelper && (
                  <FormHelperText error={showError}>
                    {showError ? meta.error : helper}
                  </FormHelperText>
                )}
              </div>
            )
          }

          return (
            <FormControl fullWidth={fullWidth} error={showError} className={className}>
              {label && <Typography variant={'subheading'}>{label}</Typography>}
              <MuiCheckbox {...input} value="" {...rest} />
              {showHelper && <FormHelperText>{showError ? meta.error : helper}</FormHelperText>}
            </FormControl>
          )
        }}
      </Field>
    )
  }
}
