import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiSelect from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

export class Select extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    helper: PropTypes.string,
    label: PropTypes.any,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
  }

  render() {
    const { name, helper, label, fullWidth, className, children, ...rest } = this.props
    return (
      <Field name={name}>
        {({ input, meta }) => {
          const showError = meta.touched && !!meta.error
          const showHelper = !!(helper || showError)
          return (
            <FormControl
              fullWidth={fullWidth}
              error={showError}
              className={className}
              margin={'normal'}
            >
              {typeof label === 'string' && <Typography variant={'subheading'}>{label}</Typography>}
              {label !== undefined && typeof label !== 'string' && label}
              <MuiSelect
                style={input.value ? null : { color: '#a7a7a7' }}
                displayEmpty={true}
                {...rest}
                {...input}
              >
                {children}
              </MuiSelect>
              {showHelper && <FormHelperText>{showError ? meta.error : helper}</FormHelperText>}
            </FormControl>
          )
        }}
      </Field>
    )
  }
}
