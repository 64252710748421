import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, addLocaleData } from 'react-intl'
import { Loading } from '../Loading'
import { flatten } from '../flatten'

export class Intl extends Component {
  static propTypes = {
    /* a function that takes the locale and return a promise */
    /* the promise should resolve a javascript object of translations*/
    translationLoader: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      id: PropTypes.string,
      locale: PropTypes.string,
    }).isRequired,
    onLocaleSwitched: PropTypes.func,
    onLocaleSwitchFail: PropTypes.func,
  }

  static defaultProps = {
    onLocaleSwitched: () => {},
    onLocaleSwitchFail: () => {},
  }

  state = {
    translations: null,
    locale: { id: '', locale: '' },
    loading: false,
  }

  componentDidMount() {
    const { locale, disable } = this.props
    if (disable) {
      this.setState({ translations: {} })
    } else this.switchLocale(locale)
  }

  switchLocale = (locale) => {
    this.setState({ loading: true }, async () => {
      const localeData = await import(`react-intl/locale-data/${locale.id.split('-')[0]}`)
      this.props
        .translationLoader(locale.id)
        .then((translations) => {
          addLocaleData([...localeData])
          this.setState(
            {
              translations: flatten(translations),
              locale,
              loading: false,
            },
            () => this.props.onLocaleSwitched(locale)
          )
        })
        .catch((error) => {
          this.setState({ loading: false }, () => this.props.onLocaleSwitchFail(locale))
          console.error(error)
        })
    })
  }

  render() {
    const { children } = this.props
    const { translations, locale, loading } = this.state
    const isfunc = typeof children === 'function'

    if (!translations) {
      return <Loading message="Loading..." />
    }

    return (
      <IntlProvider locale={locale ? locale.id.split('-')[0] : null} messages={translations}>
        {isfunc ? children(locale, this.switchLocale, loading) : children}
      </IntlProvider>
    )
  }
}
